<template>

    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"
    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}部门</div>

        <div class="content" v-loading="loading">
            <el-form class="" label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="上级部门" prop="parentId" ref="parentId">

                    <el-cascader
                        v-model="form.parentId"
                        :options="treeselectOptions"
                        :props="{ expandTrigger: 'hover',checkStrictly:true,emitPath:false,label:'deptName',value:'id' }"
                        @change="changeParentId"></el-cascader>

                </el-form-item>


                <el-form-item label="部门名称" prop="deptName" ref="deptName">
                    <el-input v-model="form.deptName" placeholder="请输入部门名称" @keyup.enter.native="submit"></el-input>
                </el-form-item>
                <el-form-item label="负责人" prop="leader" ref="leader">
                    <el-input v-model="form.leader" placeholder="请输入负责人" @keyup.enter.native="submit"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone" ref="phone">
                    <el-input v-model="form.phone" placeholder="请输入联系电话" @keyup.enter.native="submit"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email" ref="email">
                    <el-input v-model="form.email" placeholder="请输入邮箱" @keyup.enter.native="submit"></el-input>
                </el-form-item>


            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import mixinsForm from '@/mixins/form'

import {initTreeData} from "@/util/common";
export default {
    components: {},
    mixins: [mixinsForm],
    props: {
        listData: {
            type: Array,
            default:function(){
                return []
            }
        },
    },
    data() {
        return {
            api:{
                save:this.$api['admin/dept'].save,
            },
            treeselectOptions:[],
            rules: {
                parentId: [
                    {required: true, message: '请选择上级部门', trigger: ['blur','change']},
                    {
                        trigger: ['change', 'blur'],
                        validator: (rule, value, callback) => {
                            if(this.form.id==value){
                                callback(new Error('上级部门不能为自身'));
                            };
                            callback();
                        }
                    }
                ],
            },
        };
    },
    computed: {},
    watch: {
        options:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal,oldVal){
                this.getTreeselectOptions();
            }
        },
        listData:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal,oldVal){
                this.getTreeselectOptions();
            }
        },
    },
    methods: {
        getTreeselectOptions(){
            var arr = [];
            if(this.options.id){
                this.listData.some(r=>{
                    if(r.id!=this.options.id){
                        arr.push(r.deepClone())
                    }
                })
            }else {
                this.listData.some(r=>{
                    arr.push(r.deepClone())
                })
            };
            this.treeselectOptions = [
                {
                    id:'0',
                    deptName:'顶级部门',
                    children:initTreeData(arr)
                }
            ];
        },
        changeParentId(e){
            this.$refs.form.validateField('parentId');
        },
    },
};
</script>
